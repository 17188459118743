<template>
		<el-card class="budget-plan-config-container">
			<advanced-search-bar>
				<template>
					<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.website" :parent="false" @onload="q.website=queryForm.website;getList()" :clearable="false"></form-item-sites>
					<form-item>
						<el-button type="primary" size="mini" @click="addItem">添加新配置</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">搜 索</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item label="渠道:">
						<el-select v-model="queryForm.channel" size="mini" :clearable="false">
							<el-option v-for="item in channels" :label="item.label" :value="item.value" :key="item.value"></el-option>
						</el-select>
					</form-item>
					<form-item label="出价方式:">
						<el-select v-model="queryForm.bidType" clearable size="mini">
							<el-option v-for="item in bidTypes" :label="item.label" :value="item.value" :key="item.value"></el-option>
						</el-select>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<el-table :data="tableData" size="mini" header-row-class-name="table-header" height="auto">
			    <el-table-column
					align="center"
					prop="website"
					label="站点"
					min-width="100">
			    </el-table-column>
				<el-table-column
					align="center"
					prop="channel"
					label="渠道"
					min-width="100">
				</el-table-column>
				<el-table-column
					align="center"
					prop="bidtype"
					label="出价方式"
					min-width="150">
				</el-table-column>
				<el-table-column
					align="center"
					prop="config"
					label="调整规则"
					min-width="400">
					<template slot-scope="scope">
						<div v-for="(strategy,index) in scope.row.strategies" :key="index" :class="{'border-bottom': index !== scope.row.strategies.length -1}" class="text-left">
							<div>Expressions</div>
							<div>{{strategy|expressions}}</div>
							<div>Strategy</div>
							<div>{{strategy|strategy}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column
					align="center"
					prop="updatedBy.name"
					label="配置人"
					min-width="150">
				</el-table-column>
				<el-table-column
					align="center"
					prop="updatedAt"
					label="配置更新时间"
					min-width="150">
					<template slot-scope="scope">
						{{scope.row.updatedAt|formatDate}}
					</template>
				</el-table-column>
				<el-table-column
					align="center"
					label="状态"
					min-width="100">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.active" @change="updateStatus(scope.row)"></el-switch>
					</template>
				</el-table-column>
				<el-table-column
					align="center"
					label="操作"
					min-width="180">
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="editItem(scope.row)">编辑</el-button>
						<el-button type="text" class="text-danger" size="mini" @click="delItem(scope.row, scope.$index)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
			<el-dialog :visible.sync="visible.edit" width="1400px" :show-close="false" :before-close="handleClose">
				<div slot="title" class="d-flex flex-row align-content-center">
					<el-form ref="form" :model="form">
						<el-row :gutter="20">
							<el-col :span="7">
								<el-form-item-sites :abbr="true" v-model="form.websiteCode" label-width="60px" prop="channel" size="mini"></el-form-item-sites>
							</el-col>
							<el-col :span="7">
								<el-form-item label="渠道:" label-width="60px">
									<el-select v-model="form.channel" :clearable="false" size="mini">
										<el-option v-for="item in channels" :label="item.label" :value="item.value" :key="item.value"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="10">
								<el-form-item label="出价方式:" label-width="80px" prop="bidType">
									<el-select v-model="form.bidType" :clearable="false" size="mini">
										<el-option v-for="item in bidTypes" :label="item.label" :value="item.value" :key="item.value"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</div>
				<el-row v-for="(strategy, index) in form.strategies" :key="index" :gutter="20" class="mb-3">
					<el-col :span="22">
						<div class="border p-3">
							<el-row>
								<el-col :span="13">
									<div v-for="(expression, index1) in strategy.condition.expressions" :key="`${index}-${index1}`" class="d-flex flex-row align-items-center mb-1">
										<el-select v-model="expression.operator" size="mini" style="width:80px;" :style="{'opacity': index1 === 0 ? 0:1}" :disabled="index1 === 0">
											<el-option label="AND" value="AND"></el-option>
											<el-option label="OR" value="OR"></el-option>
										</el-select>
										<el-input class="text-center mx-1" style="width:60px;" v-model.trim="expression.valueLeft" size="mini"></el-input>
										<el-select v-model="expression.symbolLeft" style="width:60px;" size="mini" placeholder=" ">
											<el-option label="" value=""></el-option>
											<el-option label="<" value="<"></el-option>
											<el-option label="<=" value="<="></el-option>
										</el-select>
										<el-select v-model="expression.variables" style="width:100px;" :clearable="false" size="mini" class="mx-1">
											<el-option v-for="item in budgetRuleROIVariables" :label="item" :value="item" :key="item"></el-option>
										</el-select>
										<el-select v-model="expression.symbolRight" style="width:60px;" size="mini" placeholder=" ">
											<el-option label="" value=""></el-option>
											<el-option label="<" value="<"></el-option>
											<el-option label="<=" value="<="></el-option>
										</el-select>
										<el-input class="text-center mx-1" style="width:60px;" v-model.trim="expression.valueRight" size="mini"></el-input>
										<el-button v-if="strategy.condition.expressions.length > 1" style="border:1px dashed;width:60px;" size="mini" @click="delExpression(strategy.condition.expressions, index1)">-删除</el-button>
										<el-button v-if="index1 === strategy.condition.expressions.length-1" style="border:1px dashed;width:60px;" size="mini" @click="addExpression(strategy.condition.expressions)">+添加</el-button>
									</div>
								</el-col>
								<el-col :span="11">
									<div class="d-flex flex-row align-items-center">
										<small>Budget</small>
										<el-select v-model="strategy.strategy.functionName" size="mini" class="mx-1" style="width:80px;">
											<el-option v-for="item in budgetRuleFuncNames" :label="item.value" :value="item.key" :key="item.key"></el-option>
										</el-select>
										<small v-if="strategy.strategy.functionName !== 'EQ'">(</small>
										<template v-for="(detail, index) in strategy.strategy.details">
											<template v-if="strategy.strategy.functionName !== 'EQ' || (strategy.strategy.functionName === 'EQ' && index === 0)">
												<small v-if="index > 0 && strategy.strategy.functionName !== 'EQ'">,</small>
												<el-select v-model="detail.variable" size="mini" style="width:120px;" class="budget-strategy-select">
													<el-option v-for="item in budgetRuleBudgetVariables" :label="item" :value="item" :key="item"></el-option>
												</el-select>
												<small class="mx-1">x</small>
												<el-input v-model.trim="detail.number" style="width:60px;" size="mini"></el-input>
											</template>
										</template>
										<small v-if="strategy.strategy.functionName !== 'EQ'">)</small>
									</div>
								</el-col>
							</el-row>
						</div>
					</el-col>
					<el-col :span="2">
						<div class="d-flex flex-column align-items-center">
							<div>
								<el-button v-if="index === form.strategies.length-1" style="border:1px dashed;width:60px;" size="mini" @click="addStrategy">+添加</el-button>
							</div>
							<div>
								<el-button v-if="form.strategies.length>1"  type="text" size="mini" class="text-danger" @click="delStrategy(index)">删除</el-button>
							</div>
						</div>
					</el-col>
				</el-row>
				<div slot="footer" class="dialog-footer">
					<el-button size="mini" @click="visible.edit=false" >取 消</el-button>
					<el-button type="primary" size="mini" @click="saveAction">确 认</el-button>
				</div>
			</el-dialog>
		</el-card>
</template>

<script>
	import Moment from 'moment'
	import Common from '@/common/mixins/common.js'
	import Page from '@/common/mixins/page.js'
	import {uuid} from 'vue-uuid'
	import {mapGetters} from 'vuex'
	import Config from '../mixins/index.js'
	export default {
		mixins: [Common, Page, Config],
		inject: ['adminLayout'],
		data() {
			return {
				queryForm: {
					bidType: '',
					website: '',
					channel: '',
				},
				q: {},
				tableData: [],
				namespace: 'budget-plan',
				visible: {
					edit: false,
				},
				selectedItem: {},
				form: {
					bidType: '',
					channel: '',
					websiteCode: '',
					strategies: []
				},
				fetchOnCreated: false
			}
		},
		computed: {
			...mapGetters({
				channels: 'budget-plan/channels',
				bidTypes: 'budget-plan/bidTypes',
				budgetRuleROIVariables: 'budget-plan/budgetRuleROIVariables',
				budgetRuleBudgetVariables: 'budget-plan/budgetRuleBudgetVariables',
				budgetRuleFuncNames: 'budget-plan/budgetRuleFuncNames'
			})
		},
		created() {
			this.queryForm.channel = this.channels[0].value
		},
		methods: {
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.page,
						...this.q
					},
					trigger
				}
				return this.$store.dispatch(`${this.namespace}/getConfigs`, payload)
			},
			handleList(content) {
				this.tableData = content.map(v => {
					return {
						...v,
						strategies: JSON.parse(v.config)
					}
				})
			},
			searchEvent() {
				this.q = {
					...this.queryForm
				}
				this.getList()
			},
			updateStatus(row) {
				this.$store.dispatch(`${this.namespace}/activeConfig`, {id: row.id, active: row.active})
			},
			delItem(row, index) {
				this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.adminLayout.showLoading()
					this.$store.dispatch(`${this.namespace}/delConfig`, row.id)
					.then(res => {
						this.adminLayout.hideLoading()
						this.tableData.splice(index, 1)
					})
					.catch(err => {
						this.$showErrMsg(err)
						this.adminLayout.hideLoading()
					})
				})
			},
			editItem(row) {
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/getConfig`, row.id)
				.then(res => {
					this.adminLayout.hideLoading()
					this.form = {
						id: res.id,
						bidType: res.bidtype,
						websiteCode: res.website,
						channel: res.channel,
						strategies: JSON.parse(res.config)
					}
					this.visible.edit = true
				})
				.catch(err => {
					this.adminLayout.hideLoading()
					this.$showErrMsg(err)
				})
			},
			addItem() {
				this.form = {
					websiteCode: this.queryForm.website,
					channel: this.queryForm.channel,
					bidType: this.queryForm.bidType,
					strategies: [{
						condition :{
							expressions: [{
								operator: 'OR',
								valueLeft: null,
								valueRight: null,
								variables: 'L7D_ROI',
								sybmolLeft: '',
								symbolRight: ''
							}]
						},
						strategy: {
							details: [{
								variable: 'CURRENT_BUDGET',
								number: '',
								operator: '*'
							}, {
								variable: 'CURRENT_BUDGET',
								number: '',
								operator: '*'
							}],
							functionName: 'MAX'
						}
					}]
				}
				this.visible.edit = true
			},
			delStrategy(index) {
				this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.form.strategies.splice(index, 1)
				})
			},
			addStrategy() {
				this.form.strategies.push({
						condition :{
							expressions: [{
								operator: 'OR',
								valueLeft: null,
								valueRight: null,
								variables: 'L7D_ROI',
								sybmolLeft: '',
								symbolRight: ''
							}]
						},
						strategy: {
							details: [{
								variable: 'CURRENT_BUDGET',
								number: '',
								operator: '*'
							}, {
								variable: 'CURRENT_BUDGET',
								number: '',
								operator: '*'
							}],
							functionName: 'MAX'
						}
					})
			},
			delExpression(expressions, index) {
				expressions.splice(index, 1)
			},
			addExpression(expressions) {
				expressions.push({
					operator: 'OR',
					valueLeft: null,
					valueRight: null,
					variables: 'L7D_ROI',
					sybmolLeft: '',
					symbolRight: ''
				})
			},
			saveAction() {
				const {id, channel, websiteCode, bidType, strategies} = this.form
				if (!channel || !websiteCode || !bidType) {
					this.$showErrMsg('请完整填写规则基本信息')
					return
				}
				const v = strategies.reduce((lhs, rhs) => {
					const {condition, strategy} = rhs
					const {expressions} = condition
					const {details, functionName} = strategy
					const res_expressions = expressions.reduce((l, r) => {
						const c1 = r.symbolLeft && isNaN(r.valueLeft) === false
						const c2 = r.sybmolRight && isNaN(r.valueRight) === false
						return l && (c1 || c2)
					}, true)
					var res_details = true
					if (functionName !== 'EQ') {
						res_details = isNaN(details[0].number) === false && isNaN(details[1].number) === false
					} else {
						res_details = isNaN(details[0].number) === false
					}
					return lhs && res_expressions && res_details
				}, true)
				if (!v) {
					this.$showErrMsg('配置的表达式存在异常，请检查后重新提交')
					return
				}
				const body = {
					...this.form,
					strategies: this.form.strategies.map(v => {
						const {condition, strategy} = v
					})
				}
				var api = null
				if (id) {
					api = this.$store.dispatch(`${this.namespace}/updateConfig`, {id, body: {...this.form}})
				} else {
					api = this.$store.dispatch(`${this.namespace}/newConfig`, {...this.form})
				}
				this.adminLayout.showLoading(true)
				api
				.then(res => {
					this.adminLayout.hideLoading()
					this.visible.edit = false
					this.getList()
				})
				.catch(err => {
					this.$showErrMsg(err)
					this.adminLayout.hideLoading()
				})
			}
		}
	}
</script>
<style lang="scss">
	.budget-plan-config-container {
		.el-table .table-header th {
			background: #f3f3f3;
		}
		.el-select .el-input__suffix { display: none; }
	}
	.budget-strategy-select {
		.el-input--mini .el-input__inner {
			font-size: 10px;
			padding-right: 0;
		}
	}
</style>

